$border-radius: 0;
$input-border-radius: 0;
$input-border-radius-sm: 0;

$input-focus-border-color: skyblue;
$input-focus-box-shadow: 0 0 5px 0px skyblue;

$form-group-margin-bottom: 24px;

$fa-font-path: "../../fonts/fontawesome4";

$simple-line-font-path: "../../fonts/simple-line-icons/";