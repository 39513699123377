html, body
{
    height: 100%;
}

::placeholder
{
    opacity: 0.35 !important;
}

.th-small
{
    width: 1px !important;
    white-space: nowrap;
}

.nav-main a > i
{
    width: 14px;
    text-align: center;
}

.js-dataTable-full, .dyno
{
    display: none
}

.si-lg, .fa-lg
{
    font-size: $h5-font-size;
}

.select2
{
    width: 100% !important;
}

.select2-results__option--disabled
{
    display: none;
}

.modal.show
{
    display: flex !important;
    align-items: center;
}

.new-lines .css-control
{
    display: block;
}

.login-container
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: #f0f2f5;

    .login-mask
    {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        max-width: 400px;
        margin: auto;
        border: 1px solid rgba(black, 0.25);
        border-radius: 3px;
        padding: 1rem;
        background-color: white;

        img
        {
            width: 150px;
            height: auto;
        }

        form
        {
            width: 100%;

            button[type="submit"]
            {
                width: 100%;
                //border-radius: 0.3rem;
            }
        }
    }
}

.logo
{
    width: 100%;
    height: auto;
}

.content-header
{
    display: block;
    font-size: 25px;
}

.sidebar-content
{
    //border-right: 10px solid theme-color('primary');
}

h1
{
    color: theme-color('primary') !important;
}

#filters
{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &:first-child
    {
        padding-left: 0;
    }

    &:last-child
    {
        border-right: 0;
        padding-right: 0;
    }

    .choice
    {
        background-color: rgba(theme-color('primary'), 0.2);
        color: theme-color('primary');
        cursor: pointer;

        &.active
        {
            background-color: theme-color('primary');
            color: white;
        }
    }

    .select2
    {
        margin-right: 20px;
        width: 100% !important;
        max-width: 400px;

        .select2-selection
        {
            background-color: rgba(theme-color('primary'), 0.2) !important;

            .select2-selection__rendered
            {
                color: theme-color('primary');
                font-weight: bold;
            }
        }
    }

    .filter-reset-btn
    {
        color: theme-color('primary');
        font-size: 25px;
        cursor: pointer;
    }
}

tr.refresher
{
    background: rgba(black, .15) !important;
}

// Hidden columns - Fix
table
{
    width: 100% !important;
}

.table th,
.table td
{
    width: auto !important;

    &:last-of-type
    {
        width: 0 !important;
    }
}
